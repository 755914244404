<template>
  <component
    :is="component"
    :to="props.to"
    class="rounded-sm border text-center transition-opacity default:relative default:inline-flex default:items-center default:justify-center"
    :class="{
      'px-3 leading-4': props.size === 'md',
      'py-[0.5rem]': props.size === 'md' && !props.inputHeight,
      'py-[0.75rem]': props.inputHeight,
      'px-4 py-2 text-lg': props.size === 'lg',
      'border-blue-600 text-inherit': props.variant === 'outlined',
      'border-blue-600 bg-blue-700 text-white': props.variant === 'primary',
      'border-red-400 bg-red-500 text-white': props.variant === 'danger',
      'pointer-events-none opacity-50': props.disabled,
      'w-full': props.fluid,
    }"
    @click.capture="onClick"
  >
    <Transition>
      <div
        v-if="props.loading"
        class="absolute inset-0 flex items-center justify-center"
      >
        <v-spinner
          size="1.3em"
          :class="{
            'border-white/30 border-l-white': props.variant === 'primary' || props.variant === 'danger',
          }"
        />
      </div>
    </Transition>
    <div
      :class="{
        'opacity-0': props.loading,
      }"
    >
      <Icon
        v-if="props.icon"
        :name="props.icon"
        class="!align-bottom"
        :class="{ 'mr-1.5': $slots.default }"
      />

      <span
        v-if="$slots.default"
        :class="{ relative: props.icon }"
      >
        <slot />
      </span>
    </div>
  </component>
</template>
<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    to?: string
    variant?: "outlined" | "primary" | "danger"
    size?: "md" | "lg"
    loading?: boolean
    disabled?: boolean
    icon?: string
    fluid?: boolean
    inputHeight?: boolean
  }>(),
  {
    variant: "outlined",
    size: "md",
  }
)

const component = computed(() => {
  if (props.to) {
    return resolveComponent("NuxtLink")
  }

  return "button"
})

function onClick(e: MouseEvent) {
  if (props.loading) {
    e.preventDefault()
    e.stopImmediatePropagation()
  }
}
</script>
